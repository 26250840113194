
.main-content {
    .main-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .header-input-group {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .theory-input-item {
                margin-right: 10px;
            }
            .title-txt {
                margin-right: 10px;
            }
        }
    }
    .main-body {
        width: 100%;
        height: calc(100% - 80px);
        &::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .main-footer {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.main-body {
    &::v-deep .el-table {
        margin-top: 14px;
        .table-header-deep {
            background-color: rgb(242, 243, 250);
        }
    }
    .topic-title {
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 35px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .deep-blue {
        &::v-deep.el-button--text {
            color: #1122D8;
            font-size: 14px;
        }
    }
    .light-red {
        &::v-deep.el-button--text {
            color: #EB6547;
            font-size: 14px;
        }
    }
    .el-message-box__btns {
        .deep-blue-confirm {
            &::v-deep.el-button--primary {
                background-color: #000;
            }
        }
    }
}
.import-dialog {
    .item {
        margin-bottom: 10px;
    }
    .topic-tmpl-input {
        display: none;
    }
    .file-name-tag {
        margin-top: 10px;
    }
}


